
.Navigation {
    padding-left: 1vw;
  }

.Home {
    color: #CED9DF;
    font-size: 4vw;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .Home-name {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .Home-embeds {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .About {
    color: #CED9DF;
    font-size: 2vw;
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }
  
  .About-left{
    align-self: stretch;
    display: flex;
    flex-direction: column;
    width: 30%;
    border-right: 1px solid #CED9DF;
    justify-content: stretch;
    align-items: center;
  }
  .About-left-content{
    margin-top: 5vh;
    margin-right: 1vw;
    margin-left: 1vw;
  }

  .About-right{
    height: 100%;
    width: 70%;
    margin-left: 2vh;
    justify-content: center;
    align-items: center;
  }